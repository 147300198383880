@use 'sass:color';

@use '../mastodon/functions' with (
  $darken-multiplier: 1,
  $lighten-multiplier: -1
);

$black: #000000; // Black
$white: #ffffff; // White
$blurple-500: #6364ff; // Brand purple
$grey-600: hsl(240deg, 8%, 33%); // Trout
$grey-100: hsl(240deg, 51%, 90%); // Topaz

$classic-base-color: hsl(240deg, 16%, 19%);
$classic-secondary-color: hsl(255deg, 25%, 88%);
$classic-highlight-color: $blurple-500;

@use '../mastodon/variables' with (
  $success-green: color.adjust(
      hsl(138deg, 32%, 35%),
      $lightness: 8%,
      $space: hsl
    ),
  $base-overlay-background: $white,

  $ui-base-color: $classic-secondary-color,
  $ui-base-lighter-color: hsl(250deg, 24%, 75%),
  $ui-secondary-color: $classic-base-color,

  $ui-button-secondary-color: $grey-600,
  $ui-button-secondary-border-color: $grey-600,
  $ui-button-secondary-focus-color: $white,
  $ui-button-tertiary-color: $blurple-500,
  $ui-button-tertiary-border-color: $blurple-500,

  $primary-text-color: $black,
  $darker-text-color: $classic-base-color,
  $lighter-text-color: $classic-base-color,
  $highlight-text-color: $classic-highlight-color,
  $dark-text-color: hsl(240deg, 16%, 32%),
  $light-text-color: hsl(240deg, 16%, 32%),
  $inverted-text-color: $black,

  $action-button-color: hsl(240deg, 16%, 45%),
  $emojis-requiring-inversion: 'chains',

  $emoji-reaction-color: #dfe5f5,
  $emoji-reaction-selected-color: #9ac1f2
);
